/** Helper для работы с пользователем. */
class userHelper {
    /**
     * Возвращает полное наименовани пользователя.
     * @param lastName Фамилия.
     * @param firstName Имя.
     * @param patronymic Отчество.
     * @param phoneNumber Номер телефона.
     * @returns Полное наименоавние пользователя.
     */
    public getFullNameUser = (lastName: string | undefined, firstName: string | undefined, patronymic: string | undefined, phoneNumber: string | undefined) : string => {
        if(!lastName) {
            return `${phoneNumber}`;
        }
    
        let fullName = lastName;
        if(firstName) {
            fullName += ` ${firstName}`
        }
        if(patronymic) {
            fullName += ` ${patronymic}`
        }
        return fullName;
    }
};

export default new userHelper();